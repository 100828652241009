<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createRentalCar"
        v-if="hasPermission('create_rental_car')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Rental Company"
              label-for="rentalCompany"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <v-select
                id="rentalcompanies"
                v-model="selectedRentalCompany"
                :options="rentalcompanies"
                placeholder="Select Rental Company"
                label="name"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="rentalCars"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_rental_car')"
        :per-page="0"
      >
        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>
        <template #custom-foot>
          <b-tr variant="light">
            <b-td :colspan="fields.length">
              <span class="font-weight-bolder">
                Total Rows: {{ totalItems }}
              </span>
            </b-td>
          </b-tr>
        </template>
        <template #cell(name)="row">
          {{ truncate(row.item.name, 20) }}
        </template>
        <template #cell(rental_company)="row">
          {{ truncate(row.item.rental_company_name, 20) }}
        </template>

        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editRentalCar(row.item)"
            v-if="hasPermission('update_rental_car')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeRentalCar(row.item)"
            v-if="hasPermission('delete_rental_car')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
    </b-card>
    <rental-car-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${rentalCarCreateModalCount}`"
    />
    <rental-car-edit-modal
      :rentalCar="rentalCar"
      @modalClosed="onModalClosed"
      :key="`edit-${rentalCarEditModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import RentalCarCreateModal from "@/components/admin/rentalCar/RentalCarCreateModal.vue";
import RentalCarEditModal from "@/components/admin/rentalCar/RentalCarEditModal.vue";

export default {
  components: {
    RentalCarCreateModal,
    RentalCarEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "rental_company", label: "Rental Company" },
        { key: "amount", label: "Amount" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Created At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      rentalCars: [],
      rentalCar: null,
      RentalCarCreateModal: 0,
      RentalCarEditModal: 0,
      rentalCarCreateModalCount: 0,
      rentalCarEditModalCount: 0,
      searchTypes: [{ value: 1, name: "Rental Company" }],
      searchType: null,
      name: "",
      selectedRentalCompany: null,
      rentalcompanies: [],
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
    await this.fetchRentalCompany();
  },
  methods: {
    ...mapActions({
      getRentalCars: "appData/getRentalCars",
      deleteRentalCar: "appData/deleteRentalCar",
      getCompanyPublic: "appData/getRentalCompanysPublic",
    }),

    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            break;
        }
      } else {
        this.selectedRentalCompany = null;
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchRentalCompany() {
      try {
        const res = await this.getCompanyPublic();
        this.rentalcompanies = res.data;
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    },
    async fetchPaginatedData() {
      try {
        const res = await this.getRentalCars({
          pageNumber: this.currentPage,
          rental_company: this.selectedRentalCompany
            ? this.selectedRentalCompany.id
            : null,
        });
        this.rentalCars = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createRentalCar() {
      this.rentalCarCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("rental-car-create-modal");
      });
    },
    editRentalCar(rentalCar) {
      this.rentalCar = rentalCar;
      this.rentalCarEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("rental-car-edit-modal");
      });
    },
    async removeRentalCar(rentalCar) {
      this.rentalCar = rentalCar;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteRentalCar({
              pk: this.rentalCar.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "RentalCar deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.rentalCars.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
