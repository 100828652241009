<template>
  <b-modal
    id="rental-car-create-modal"
    title="Rental Car Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Car Create</h2>
    </template>
    <validation-observer ref="rentalCarCreateFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="name">
          <template #label>
            Car Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Car Name"
            required
            @keydown="checkMaxLength($event, 'name', 400)"
          />
        </b-form-group>

        <b-form-group label-for="amount">
          <template #label>
            Amount <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="amount"
            v-model="amount"
            placeholder="Amount"
            type = "number"
            min="1"
            step="1"
            required
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="description">
          <template #label>
            Description
          </template>
          <quill-editor id="description" v-model="description"  />
        </b-form-group>

        <b-form-group label-for="rental_company">
          <template #label>
            Company <span class="text-danger">*</span>
          </template>
          <validation-provider
                #default="{ errors }"
                name="Company"
                rules="required" >
          <v-select label="name" v-model="company" placeholder="Select Company" :options="companys" autoscroll :clearable="true"></v-select>
          <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> 
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <validation-provider
          v-slot="{ errors }"
          name="Image"
          rules="required"
        >
        
          <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="input-2"
            description="Upload an image in PNG, JPG, or JPEG format."
            :invalid-feedback="errors[0]"
          >
          <template #label>
            Image <span class="text-danger">*</span>
          </template>
            <b-form-file
              ref="imageInput"
              id="documents"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              required
              plain
              accept=".png,.jpg,.jpeg"
              v-model="files"
              @change="validateFileType($event)"
            ></b-form-file>
          </b-form-group>
        </validation-provider>

        <b-form-group class="mt-2 text-right">
          <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1"
          >
            Save
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor
  },
  mixins: [util],
  data() {
    return {
      required,
      name: "",
      description: "",
      amount: "",
      company: "",
      companys: [],
      status: "",
      files: [],
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  methods: {
    ...mapActions({
      createRentalCar: "appData/createRentalCar",
      getCompanyPublic: "appData/getRentalCompanysPublic",
    }),
    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!allowedTypes.includes(file.type)) {
        event.target.value = null;
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity('Please upload a PNG, JPG, or JPEG image file.');
          this.$refs.imageInput.$el.reportValidity();
        }
      } else {
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity('');
        }
      }
    },
    async validationForm() {
        const success = await this.$refs.rentalCarCreateFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async submit() {
      try {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('description', this.description);
        formData.append('amount', this.amount);
        formData.append('rental_company', this.company.id);
        formData.append('status', this.status);
        formData.append('image', this.files);
        formData.append('created_by', this.getLoggedInUser.id); 
        formData.append('updated_by', this.getLoggedInUser.id); 

        const res = await this.createRentalCar(formData);
        if (res.status === 200) {
          this.$swal({
            title: "Rental Car created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("rental-car-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.description = "";
      this.status = "";
      this.amount =  "";
      this.company =  "";
      this.files = null;
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return this.amount !== "" && Number.isInteger(Number(this.amount)) && Number(this.amount) > 0;
    },
  },
  async mounted(){
    const res_companys = await this.getCompanyPublic();
    this.companys = res_companys.data;
  }
};
</script>
  
<style></style>
